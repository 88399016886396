import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import Flex from "components/common/Flex";
import Section from "components/common/Section";
import paths from "routes/paths";

const AuthCardLayout = ({ leftSideContent, children, footer = true }) => {
  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xxl={5} className="py-3 position-relative">
          <Card className="overflow-hidden z-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col md={5} className="text-white text-center bg-card-gradient">
                  <div className="position-relative p-4 pt-md-5 pb-md-7">
                    <div className="z-1 position-relative" data-bs-theme="light">
                      <Link
                        className="link-light mb-4 font-sans-serif fw-bolder fs-5 d-inline-block"
                        to={paths.cardLogin}
                      >
                        MSME FUND
                      </Link>
                      <p className="opacity-75 text-white">Nepal Rastra Bank</p>
                    </div>
                  </div>
                  <div className="mt-3 mb-4 mt-md-4 mb-md-5" data-bs-theme="light">
                    {leftSideContent}
                  </div>
                </Col>
                <Col md={7} as={Flex} alignItems="center" justifyContent="center">
                  <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
};

export default AuthCardLayout;
