import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AllowedAccess } from "react-permission-role";

const MyIconButton = ({
  icon,
  tooltip,
  onClick,
  className = "",
  disabled = false,
  permissions = [],
  userPermissions = [],
  fallback = null, // Render `null` by default if access is denied
}) => {
  return (
    <AllowedAccess
      permissions={permissions}
      userPermissions={userPermissions}
      renderAuthFailed={fallback}
    >
      <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
        <span
          onClick={disabled ? undefined : onClick}
          className={`${className} ${disabled ? "disabled-icon" : ""}`}
          style={{
            cursor: disabled ? "not-allowed" : "pointer",
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <FontAwesomeIcon icon={icon} />
        </span>
      </OverlayTrigger>
    </AllowedAccess>
  );
};

// Define PropTypes for the component
MyIconButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired, // FontAwesome icon
  tooltip: PropTypes.string.isRequired, // Tooltip text
  onClick: PropTypes.func.isRequired, // Click handler
  className: PropTypes.string, // Additional class names
  disabled: PropTypes.bool, // Disabled state
  permissions: PropTypes.arrayOf(PropTypes.string), // Required permissions
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired, // User's permissions
  fallback: PropTypes.node, // Content to render if access is denied
};

// Default Props for optional props
MyIconButton.defaultProps = {
  className: "",
  disabled: false,
  permissions: [],
  fallback: null,
};

export default MyIconButton;
