import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Modal, Table, Badge } from "react-bootstrap";
import {
  useGetSelfDeclarationTemplatesQuery,
  useCreateApplicationWithSelfDeclarationMutation,
  useUpdateApplicationMutation,
  useGetApplicationByIdQuery,
  useGetEligibilityValuesQuery,
  useReturnToBankMutation,
  useGetParticipationInOtherCallsQuery,
} from "components/services/application/ApplicationService";
import { toast } from "react-toastify";
import FileTable from "./DocumentTable";
import {
  useCreateFileWithUploadMutation,
  useGetFilesByApplicationIdQuery,
} from "components/services/application-file/ApplicationFile";
import CommentTree from "./CommentTree";
import { useGetCommentsByApplicationIdQuery } from "components/services/comment/commentService";
import { getAccessToken } from "components/utilities/sedra3/auth";
import { useGetCallApplicationByIdQuery } from "components/services/call-application/CallApplicationService";
import { DOWNLOAD_FILE, fileActionHandler } from "components/utilities/sedra3/file-downloader";
import ParticipationTable from "./OtherCallsParticipationsTable";
import { ApplicationStatusEnum } from "features/constants/application";
import MyButton from "./sedra-widgets/MyButton";

const ApplicationForm = ({
  callId,
  show,
  handleClose,
  onApplicationCreated,
  initialData = null,
  isEdit = false,
  isCreate = false,
  isDetail = false, // New prop to indicate detail mode
  submitButtonLabel = "Submit", // Default label
  submitButtonStyle = "primary", // Default style
  showValidateButton, // A prop for showing the Validate button
}) => {
  const [formData, setFormData] = useState({
    applicationAmnt: "",
    selfDeclaration: {
      answers: [],
    },
  });

  const [validationMessage, setValidationMessage] = useState("");
  const [validationMessageColor, setValidationMessageColor] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [files, setFiles] = useState([]); // State to store files locally
  const applicationId = useRef(null); // Ref to store application ID
  const [isFileValidationOk, setIsFileValidationOk] = useState(false); // State to track file validation
  const [isSaveOrSubmitLoading, setIsSaveOrSubmitLoading] = useState(false); // New loading state
  const [isSaved, setIsSaved] = useState(true); // Initially true if the form is already saved

  const [errors, setErrors] = useState({
    applicationAmnt: "", // Add an error state for the loan amount
  });

  const { user } = useSelector((state) => state.auth);
  const { data: templates, isLoading: templatesLoading } = useGetSelfDeclarationTemplatesQuery();
  const { data: eligibilityValues, isLoading: eligibilityValuesLoading } =
    useGetEligibilityValuesQuery();
  const [createApplication] = useCreateApplicationWithSelfDeclarationMutation();
  const [updateApplication] = useUpdateApplicationMutation();
  const [returnToBankUpdate] = useReturnToBankMutation();
  const [createFileWithUpload] = useCreateFileWithUploadMutation();

  const { data: commentsData, refetch: refetchComments } = useGetCommentsByApplicationIdQuery(
    applicationId.current,
    { skip: !applicationId.current }
  ); // get the all comments for the current Application
  const { data: filesData, refetch: refetchFiles } = useGetFilesByApplicationIdQuery(
    applicationId.current,
    { skip: !applicationId.current }
  ); // get the all file for the current Application
  const { refetch: refetchApplication } = useGetApplicationByIdQuery(applicationId.current, {
    skip: !applicationId.current,
  }); // get the Application

  const { data: callApplicationData, isLoading: isLoadingCallApplication } =
    useGetCallApplicationByIdQuery(callId, {
      skip: !callId, // Skip the query if callId is not provided
    });

  const {
    data: participationData,
    isLoading: isParticipationLoading,
    error: participationError,
  } = useGetParticipationInOtherCallsQuery({
    bank_id: user.bank.id,
    call_application_id: callId,
  }); // Get all the other applications that particapated in other Calls

  const minAmount = isCreate
    ? callApplicationData?.min_amnt || 0
    : initialData?.call_application.min_amnt || 0;

  const maxAmount = isCreate
    ? callApplicationData?.max_amnt || 0
    : initialData?.call_application.max_amnt || 0;

  const endDate = isCreate
    ? callApplicationData?.end_date || "N/A"
    : initialData?.call_application.end_date || "N/A";

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Mark as unsaved when form data changes
    setIsSaved(false);

    if (name === "applicationAmnt") {
      const amount = parseInt(value, 10);

      // Check if the amount is a valid number
      if (isNaN(amount)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          applicationAmnt: "Please enter a valid number.",
        }));
      } else if (amount < minAmount || amount > maxAmount) {
        // Check if the amount is within the valid range
        setErrors((prevErrors) => ({
          ...prevErrors,
          applicationAmnt: `Loan amount should be between ${minAmount} and ${maxAmount} Nepali rupees.`,
        }));
      } else {
        // Clear the error if the value is valid
        setErrors((prevErrors) => ({
          ...prevErrors,
          applicationAmnt: "", // Clear the error if within range
        }));
      }
    }

    // Update the form data regardless of validation
    setFormData({ ...formData, [name]: value });
  };

  // Reset form when Cancel is clicked
  const handleCancel = () => {
    if (initialData) {
      // Reset the form data to initial values when the cancel button is clicked
      setFormData({
        applicationAmnt: initialData.application_amnt || "",
        selfDeclaration: {
          answers: templates.data.map((template) => {
            const existingAnswer = initialData?.self_declaration?.answers?.find(
              (answer) => answer.template_id === template.id
            );
            return {
              ...(existingAnswer && { id: existingAnswer.id }),
              template_id: template.id,
              answer: existingAnswer ? existingAnswer.answer : "",
              comment: existingAnswer ? existingAnswer.comment : "",
              is_required: template.is_required,
              is_eligible: template.is_eligible,
            };
          }),
        },
      });
      setFiles(initialData.files || []); // Reset files to the initial state
      setIsSaved(true); // Mark form as saved
    } else {
      // If no initial data, clear the form
      setFormData({
        applicationAmnt: "",
        selfDeclaration: {
          answers: templates.data.map((template) => ({
            template_id: template.id,
            answer: "",
            comment: "",
            is_required: template.is_required,
            is_eligible: template.is_eligible,
          })),
        },
      });
      setFiles([]); // Clear files
      setIsSaved(true); // Mark form as saved
    }

    handleClose(); // Close the modal
  };

  const handleAnswerChange = (index, field, value) => {
    const updatedAnswers = [...formData.selfDeclaration.answers];
    updatedAnswers[index][field] = value;

    // Mark as unsaved when answers change
    setIsSaved(false);

    setFormData({ ...formData, selfDeclaration: { answers: updatedAnswers } });

    if (field === "answer" && templates.data[index].type_of_answer === "float") {
      if (!/^\d*\.?\d*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`answer_${index}`]: "Please enter a valid number",
        }));
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[`answer_${index}`];
          return newErrors;
        });
      }
    }
    validateForm(); // Validate form after every answer change
  };

  const validateForm = () => {
    let allRequiredAnswered = true;
    let allEligibleCorrect = true;

    formData.selfDeclaration.answers.forEach((answer) => {
      if (answer.is_required && !answer.answer) {
        allRequiredAnswered = false;
      }

      const eligibility = eligibilityValues?.find((ev) => ev.template_id === answer.template_id);

      if (eligibility) {
        if (eligibility.operator && eligibility.required_value_float !== null) {
          const value = parseFloat(answer.answer);
          switch (eligibility.operator) {
            case ">=":
              if (value < eligibility.required_value_float) {
                allEligibleCorrect = false;
              }
              break;
            case "<=":
              if (value > eligibility.required_value_float) {
                allEligibleCorrect = false;
              }
              break;
            case "==":
              if (value !== eligibility.required_value_float) {
                allEligibleCorrect = false;
              }
              break;
            case "!=":
              if (value === eligibility.required_value_float) {
                allEligibleCorrect = false;
              }
              break;
            default:
              break;
          }
        } else if (eligibility.required_value_bool !== null) {
          if (
            (answer.answer.toLowerCase() === "yes" && !eligibility.required_value_bool) ||
            (answer.answer.toLowerCase() === "no" && eligibility.required_value_bool)
          ) {
            allEligibleCorrect = false;
          }
        }
      }
    });

    const hasApplicationAmountError = errors.applicationAmnt !== "";

    if (!allRequiredAnswered) {
      setValidationMessage("You have not selected/entered all the answers!");
      setValidationMessageColor("text-danger");
      setIsSubmitDisabled(true);
    } else if (!allEligibleCorrect) {
      setValidationMessage("You are not eligible for submission of the loan application!");
      setValidationMessageColor("text-danger");
      setIsSubmitDisabled(true);
    } else if (hasApplicationAmountError) {
      setIsSubmitDisabled(true);
    } else {
      setValidationMessage(
        "You are eligible for submission of the loan application! Please, add the files below."
      );
      setValidationMessageColor("text-success-emphasis");
      setIsSubmitDisabled(false);
    }
  };

  useEffect(() => {
    if (templates && templates.data) {
      if (isCreate) {
        // For create mode, reset the form to empty values
        setFormData({
          applicationAmnt: "",
          selfDeclaration: {
            answers: templates.data.map((template) => ({
              template_id: template.id,
              answer: "",
              comment: "",
              is_required: template.is_required,
              is_eligible: template.is_eligible,
            })),
          },
        });
        setFiles([]); // Clear any files from previous sessions
        applicationId.current = null; // Clear application ID
      } else if (!isEdit && !isDetail) {
        // For create mode
        setFormData((prevData) => ({
          ...prevData,
          selfDeclaration: {
            answers: templates.data.map((template) => ({
              template_id: template.id,
              answer: "",
              comment: "",
              is_required: template.is_required,
              is_eligible: template.is_eligible,
            })),
          },
        }));
      } else if ((isEdit || isDetail) && initialData) {
        // For edit and detail mode
        const answers = templates.data.map((template) => {
          const existingAnswer = initialData?.self_declaration?.answers?.find(
            (answer) => answer.template_id === template.id
          );
          return {
            ...(existingAnswer && { id: existingAnswer.id }),
            template_id: template.id,
            answer: existingAnswer ? existingAnswer.answer : "",
            comment: existingAnswer ? existingAnswer.comment : "",
            is_required: template.is_required,
            is_eligible: template.is_eligible,
          };
        });

        setFormData({
          applicationAmnt: initialData.application_amnt || "",
          selfDeclaration: { answers },
        });

        setFiles(initialData.files || []);
        applicationId.current = initialData.id; // Set application ID for edit or detail mode
      }
    }
  }, [templates, isEdit, isDetail, initialData, isCreate]);

  useEffect(() => {
    validateForm();
  }, [formData, eligibilityValues]);

  useEffect(() => {
    if (show && applicationId.current) {
      if (refetchFiles) {
        refetchFiles(); // Refetch files when the modal is opened
      }
      if (refetchApplication) {
        refetchApplication(); // Refetch application when the modal is opened
      }
    }
  }, [show, applicationId.current]);

  useEffect(() => {
    if (filesData && Array.isArray(filesData.data)) {
      setFiles(filesData.data); // Set files from fetched data
    }
  }, [filesData]);

  const uploadFiles = async (appId) => {
    const newFiles = files.filter((file) => !file.id); // Filter out existing files
    for (const file of newFiles) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("category", JSON.stringify(file.categories.map((category) => category.id)));
      formData.append("comment", file.comment);

      try {
        await createFileWithUpload({
          applicationId: appId,
          fileData: formData,
        }).unwrap();
        console.log("File uploaded successfully");
      } catch (error) {
        console.error("Failed to upload file", error);
        toast.error("Failed to upload file: " + error.message);
      }
    }
    if (refetchFiles) {
      refetchFiles(); // Refetch files after uploading
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    setIsSaveOrSubmitLoading(true); // Set loading to true to prevent multiple clicks

    try {
      const applicationData = {
        application_amnt: parseInt(formData.applicationAmnt, 10),
        PFI: initialData?.PFI || user.bank.name,
        status:
          submitButtonLabel === "Return to bank"
            ? ApplicationStatusEnum.SUBMITTED
            : ApplicationStatusEnum.DRAFTED, // Always "Drafted"
        submitted: false, // Set submitted based on label
        created: new Date().toISOString().split("T")[0],
        last_updated: new Date().toISOString().split("T")[0],
        user_action_req: false,
        comment: "",
        bank_id: initialData?.bank_id || user.bank.id,
        call_application_id: callId,
        self_declaration: {
          answers: formData.selfDeclaration.answers.map((answer) => ({
            ...(answer.id && { id: answer.id }), // Include id if it exists
            template_id: answer.template_id,
            answer: answer.answer,
            comment: answer.comment,
          })),
        },
      };
      if (isEdit && initialData) {
        await updateApplication({
          id: initialData.id,
          data: applicationData,
        }).unwrap();
        if (files.length > 0) {
          await uploadFiles(initialData.id);
        }
        toast.success("Application saved successfully");
        handleClose(); // Close the modal after successful save
        onApplicationCreated(initialData.id); // Notify parent component
        setIsSaved(true); // Mark form as saved after update
      } else {
        const createdApplication = await createApplication(applicationData).unwrap();
        applicationId.current = createdApplication.data.id; // Set application ID after creation
        if (files.length > 0) {
          await uploadFiles(createdApplication.data.id);
        }
        onApplicationCreated(createdApplication.data.id); // Notify parent component
        setIsSaved(true); // Mark form as saved after update
        toast.success("Application saved successfully");
      }
    } catch (error) {
      console.error("Failed to save application", error);
      toast.error("Failed to save application: " + error.message);
    } finally {
      setIsSaveOrSubmitLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSaveOrSubmitLoading(true); // Set loading to true to prevent multiple clicks

    // Handle "Return to bank" for Application Specialists (save as draft)
    if (submitButtonLabel === "Return to bank") {
      try {
        const applicationData = {
          status: ApplicationStatusEnum.RETURNED, // Set as draft when returning
          submitted: false, // Indicate not submitted
          user_action_req: true,
        };

        if (isEdit && initialData) {
          // Update existing application as draft
          await returnToBankUpdate({
            id: initialData.id,
            data: applicationData,
          }).unwrap();
          if (files.length > 0) {
            await uploadFiles(initialData.id);
          }
          toast.success("Application returned to the applicant successfully");
        }

        handleClose(); // Close modal after return
        onApplicationCreated(initialData?.id, "Returned"); // Notify parent component
        return; // Stop further execution
      } catch (error) {
        console.error("Failed to return the application", error);
        toast.error(`Failed to return the application: ${error.message}`);
        return;
      } finally {
        setIsSaveOrSubmitLoading(false);
      }
    }

    // Validate before submission for applicants
    validateForm();
    if (
      validationMessage !==
      "You are eligible for submission of the loan application! Please, add the files below."
    ) {
      return; // Prevent submission if validation fails
    }

    try {
      // Prepare the application data for saving or submitting
      const applicationData = {
        application_amnt: parseInt(formData.applicationAmnt, 10),
        PFI: initialData?.PFI || user.bank.name,
        status:
          submitButtonLabel === "Return to bank"
            ? ApplicationStatusEnum.DRAFTED
            : ApplicationStatusEnum.SUBMITTED,
        submitted: submitButtonLabel !== "Return to bank", // True if not returning to bank
        created: new Date().toISOString().split("T")[0],
        last_updated: new Date().toISOString().split("T")[0],
        user_action_req: false,
        comment: "",
        bank_id: initialData?.bank_id || user.bank.id,
        call_application_id: callId,
        self_declaration: {
          answers: formData.selfDeclaration.answers.map((answer) => ({
            ...(answer.id && { id: answer.id }), // Include id if exists
            template_id: answer.template_id,
            answer: answer.answer,
            comment: answer.comment,
          })),
        },
      };

      if (isEdit && initialData) {
        // Update existing application
        await updateApplication({
          id: initialData.id,
          data: applicationData,
        }).unwrap();
        if (files.length > 0) {
          await uploadFiles(initialData.id);
        }
        toast.success(
          applicationData.submitted
            ? "Application submitted successfully"
            : "Application saved successfully"
        );
        onApplicationCreated(initialData.id, applicationData.submitted ? "Submitted" : "Drafted");
      } else {
        // Create a new application
        const createdApplication = await createApplication(applicationData).unwrap();
        applicationId.current = createdApplication.data.id; // Store new application ID
        if (files.length > 0) {
          await uploadFiles(createdApplication.data.id);
        }
        toast.success(
          applicationData.submitted
            ? "Application submitted successfully"
            : "Application saved successfully"
        );
        onApplicationCreated(
          createdApplication.data.id,
          applicationData.submitted ? "Submitted" : "Drafted"
        );
      }

      handleClose(); // Close modal after saving/submitting
    } catch (error) {
      console.error("Failed to create or update application", error);
      toast.error(`Failed to create or update application: ${error.message}`);
    } finally {
      setIsSaveOrSubmitLoading(false);
    }
  };

  const handleTempFileUpload = (file) => {
    setFiles((prevFiles) => [...prevFiles, file]);
  };

  const handleValidateForNRB = async () => {
    if (!initialData || !initialData.id) {
      toast.error("No application selected to validate.");
      return;
    }

    try {
      setIsSaveOrSubmitLoading(true);

      const applicationData = {
        status: ApplicationStatusEnum.VALIDATED,
        submitted: true,
        user_action_req: false,
      };

      // Update the application status to "Validated" using returnToBankUpdate
      await returnToBankUpdate({
        id: initialData.id,
        data: applicationData,
      }).unwrap();

      toast.success("Application validated successfully");

      // Close the modal after validation
      handleClose();

      // Optionally, trigger any additional callbacks, like refetching data
      onApplicationCreated(initialData.id);
    } catch (error) {
      console.error("Failed to validate application", error);
      toast.error("Failed to validate application: " + error.message);
    } finally {
      setIsSaveOrSubmitLoading(false);
    }
  };

  const handlePrint = async () => {
    if (!applicationId.current) return;

    const token = getAccessToken();
    const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/application/${applicationId.current}/generate-document`;

    try {
      // Call the fileActionHandler function to download the document
      await fileActionHandler(downloadUrl, token, DOWNLOAD_FILE);
    } catch (error) {
      toast.error("Failed to generate document: " + error.message);
    }
  };

  const renderAnswerField = (template, index) => {
    const isDisabled = isDetail; // Disable field in detail mode

    if (template.type_of_answer === "bool") {
      return (
        <div className="d-flex align-items-center">
          <Form.Check
            type="radio"
            label="Yes"
            name={`answer_${template.id}`}
            checked={formData.selfDeclaration.answers[index]?.answer === "yes"}
            onChange={(e) => handleAnswerChange(index, "answer", "yes")}
            className="me-2"
            disabled={isDisabled} // Disable in detail mode
          />
          <Form.Check
            type="radio"
            label="No"
            name={`answer_${template.id}`}
            checked={formData.selfDeclaration.answers[index]?.answer === "no"}
            onChange={(e) => handleAnswerChange(index, "answer", "no")}
            disabled={isDisabled} // Disable in detail mode
          />
        </div>
      );
    } else if (template.type_of_answer === "float") {
      return (
        <div className="d-flex align-items-center">
          <Form.Control
            type="text"
            value={formData.selfDeclaration.answers[index]?.answer || ""}
            onChange={(e) => handleAnswerChange(index, "answer", e.target.value)}
            isInvalid={!!errors[`answer_${index}`]}
            disabled={isDisabled} // Disable in detail mode
          />
          <span className="ms-1">%</span>
          <Form.Control.Feedback type="invalid">{errors[`answer_${index}`]}</Form.Control.Feedback>
        </div>
      );
    } else {
      return null;
    }
  };

  if (!user || templatesLoading || eligibilityValuesLoading) return <div>Loading...</div>;

  return (
    <Modal show={show} onHide={handleClose} className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {isDetail ? "View Application" : isEdit ? "Edit Application" : "Application Form"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ width: "100%" }}>
        {/* Badge showing submission deadline only when in detail mode */}
        {(isDetail || isEdit) && (
          <div className="d-flex justify-content-end">
            <p className="mb-1">
              <Badge
                pill
                bg="warning"
                className="badge-text-style"
              >{`Submission deadline: ${endDate}`}</Badge>
            </p>
          </div>
        )}

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="PFIName">
            <Form.Label>PFI Name</Form.Label>
            <Form.Control type="text" value={user.bank.name} readOnly />
          </Form.Group>
          <Form.Group controlId="HeadOfficeLocation">
            <Form.Label>Head Office Location</Form.Label>
            <Form.Control type="text" value={user.bank.head_office} readOnly />
          </Form.Group>
          <Form.Group controlId="applicationAmnt">
            <Form.Label>
              Loan Amount Requested, Nepali rupee{" "}
              <span style={{ color: "blue" }}>
                (the amount should be in the range {minAmount} - {maxAmount})
              </span>
            </Form.Label>
            <Form.Control
              type="number"
              name="applicationAmnt"
              value={formData.applicationAmnt}
              onChange={handleInputChange}
              readOnly={isDetail} // Set read-only in detail mode
            />

            {/* Show validation error message */}
            {errors.applicationAmnt && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.applicationAmnt}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <h5 className="my-4">Self-Declaration / Undertaking Checklist</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: "5%" }}>ID</th>
                <th style={{ width: "35%" }}>Required Information / Data</th>
                <th style={{ width: "10%" }}>Answer</th>
                <th style={{ width: "30%" }}>Comments</th>
              </tr>
            </thead>
            <tbody>
              {templates?.data?.map((template, index) => (
                <tr key={template.id}>
                  <td>{index + 1}</td>
                  <td>
                    {template.required_information}
                    <br />
                    <span style={{ color: "blue" }}>{template.hint}</span>
                  </td>
                  <td>{renderAnswerField(template, index)}</td>

                  <td>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={formData.selfDeclaration.answers[index]?.comment || ""}
                      onChange={(e) => handleAnswerChange(index, "comment", e.target.value)}
                      readOnly={isDetail} // Set read-only in detail mode
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {validationMessage && (
            <div className={`my-3 ${validationMessageColor}`}>{validationMessage}</div>
          )}

          {/* Render the ParticipationTable component */}
          <ParticipationTable
            isLoading={isParticipationLoading}
            error={participationError}
            data={participationData?.data}
          />

          {(isEdit || !isSubmitDisabled) && (
            <FileTable
              files={files}
              setFiles={setFiles}
              applicationId={applicationId.current}
              handleTempFileUpload={handleTempFileUpload}
              onValidationChange={setIsFileValidationOk}
              isDetail={isDetail} // Pass isDetail to FileTable
            />
          )}
        </Form>
        {!isDetail && ( // Hide the print button in detail mode
          <div className="d-flex justify-content-end mt-3">
            <Button variant="outline-secondary" onClick={handlePrint} disabled={!isSaved}>
              Print application form
            </Button>
          </div>
        )}
        {(isEdit || isDetail) && (
          <div className="mt-4 custom-comment-card">
            <CommentTree
              comments={commentsData?.data || []}
              applicationId={applicationId.current}
              refetchComments={refetchComments}
              isDetail={isDetail}
            />
          </div>
        )}

        {!isDetail && ( // Hide buttons in detail mode
          <div className="d-flex justify-content-end mt-3">
            <MyButton
              variant="secondary"
              onClick={handleCancel}
              label="Cancel"
              permissions={["submit_applications", "save_applications"]}
              userPermissions={user.permissions}
            />
            <MyButton
              variant="primary"
              onClick={handleSave}
              label="Save"
              disabled={isSaveOrSubmitLoading || isSubmitDisabled}
              permissions={["submit_applications", "save_applications"]}
              userPermissions={user.permissions}
              className="mx-2"
            />
            <MyButton
              variant={submitButtonStyle === "danger" ? "danger" : "primary"}
              onClick={handleSubmit}
              label={submitButtonLabel}
              style={{
                backgroundColor: submitButtonStyle === "danger" ? "#dc3545" : "#198754",
                borderColor: submitButtonStyle === "danger" ? "#dc3545" : "#198754",
              }}
              className="mx-2"
              disabled={
                isSaveOrSubmitLoading ||
                (submitButtonLabel !== "Return to bank" &&
                  (isSubmitDisabled || !isFileValidationOk))
              }
              permissions={["submit_applications", "save_applications"]}
              userPermissions={user.permissions}
            />

            {/* Validate Button */}
            {showValidateButton && (
              <MyButton
                variant="success"
                onClick={handleValidateForNRB}
                label="Validate"
                style={{
                  backgroundColor: "#198754",
                  borderColor: "#198754",
                }}
                className="mx-2"
                permissions={["submit_applications"]}
                userPermissions={user.permissions}
              />
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ApplicationForm;
