import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import paths from "routes/paths";
import messages from "components/utilities/sedra3/messages";
import { useResetPasswordMutation } from "components/services/authorization/authService";
import { validatePassword } from "components/utilities/sedra3/helpers";

const PasswordResetForm = ({ hasLabel }) => {
  // State
  const location = useLocation();
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get("token");
    if (tokenParam) setToken(tokenParam);
  }, [location]);

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordValidationError = validatePassword(formData.password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setConfirmPasswordError(messages.PASSWORDS_DO_NOT_MATCH);
      return;
    }

    setPasswordError("");
    setConfirmPasswordError("");

    try {
      const data = await resetPassword({
        new_password: formData.password,
        confirm_password: formData.confirmPassword,
        token,
      }).unwrap();

      toast.success(data.message, { theme: "colored" });
      navigate(paths.cardLogin);
    } catch (error) {
      toast.error(error.data?.detail || messages.PASSWORD_RESET_FAILURE, { theme: "colored" });
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Real-time validation for password
    if (name === "password") {
      const error = validatePassword(value);
      setPasswordError(error || "");
    }

    if (name === "confirmPassword") {
      if (value !== formData.password) {
        setConfirmPasswordError(messages.PASSWORDS_DO_NOT_MATCH);
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  return (
    <Form className={classNames("mt-3", { "text-left": hasLabel })} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "New Password" : ""}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
          isInvalid={!!passwordError}
        />
        <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Confirm Password" : ""}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
          isInvalid={!!confirmPasswordError}
        />
        <Form.Control.Feedback type="invalid">{confirmPasswordError}</Form.Control.Feedback>
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword || !!passwordError}
      >
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default PasswordResetForm;
