// RoleBasedDashboard.js
import React from "react";
import { useSelector } from "react-redux";
import { AllowedAccess } from "react-permission-role";
import CallsForApplicationPage from "layouts/CallsForApplicationComponent";
import CallForApplicationAndApplicationsStatistics from "./CallForApplicationAndApplicationsStatistics";
import UserManagementStatistics from "./UserManagementStatistics";
import { selectPermissions } from "features/auth/authSlice";
import SubmittedApplications from "components/dashboards/SEDRA3/SubmittedApplications";

const MainDashboard = () => {
  // Fetch permissions directly from Redux using selectors
  const permissions = useSelector(selectPermissions);

  return (
    <div>
      {/* UserManagementStatistics with admin permission */}
      <AllowedAccess
        permissions={["users_management"]}
        renderAuthFailed={null}
        userPermissions={permissions} // Pass the user's permissions directly
      >
        <UserManagementStatistics />
      </AllowedAccess>

      {/* CallForApplicationAndApplicationsStatistics with view-applications permission */}
      <AllowedAccess
        permissions={["submitted_applications", "view_calls_for_applicaitons"]}
        renderAuthFailed={null}
        userPermissions={permissions} // Pass the user's permissions directly
      >
        <CallForApplicationAndApplicationsStatistics />
      </AllowedAccess>

      <AllowedAccess
        permissions={["submitted_applications"]}
        renderAuthFailed={null}
        userPermissions={permissions} // Pass the user's permissions directly
      >
        <SubmittedApplications />
      </AllowedAccess>

      {/* CallsForApplicationPage with submit-calls permission */}
      <AllowedAccess
        permissions={["view_calls_for_applications_widgets"]}
        renderAuthFailed={null}
        userPermissions={permissions} // Pass the user's permissions directly
      >
        <CallsForApplicationPage itemsPerPage={10} />
      </AllowedAccess>
    </div>
  );
};

export default MainDashboard;
