import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { AllowedAccess } from "react-permission-role";

const MyButton = ({
  variant = "primary",
  onClick,
  disabled = false,
  className = "",
  style = {},
  label,
  permissions = [],
  userPermissions = [],
  fallback = null, // Render `null` by default if access is denied
}) => {
  return (
    <AllowedAccess
      permissions={permissions}
      userPermissions={userPermissions}
      renderAuthFailed={fallback}
    >
      <Button
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        className={className}
        style={style}
      >
        {label}
      </Button>
    </AllowedAccess>
  );
};

// Define PropTypes for the component
MyButton.propTypes = {
  variant: PropTypes.string, // The button's variant (e.g., "primary", "secondary")
  onClick: PropTypes.func.isRequired, // The click handler
  disabled: PropTypes.bool, // Disabled state
  className: PropTypes.string, // Additional class names
  style: PropTypes.object, // Inline styles
  label: PropTypes.string.isRequired, // The label for the button
  permissions: PropTypes.arrayOf(PropTypes.string), // The permissions required to access the button
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired, // The user's actual permissions
  fallback: PropTypes.node, // Content to render if the user lacks permissions
};

// Default Props for optional props
MyButton.defaultProps = {
  variant: "primary",
  disabled: false,
  className: "",
  style: {},
  permissions: [],
  fallback: null,
};

export default MyButton;
